[data-sonner-toaster][data-theme='light'][data-y-position='top'] {
  --normal-bg: #fff;
  --normal-border: var(--gray4);
  --normal-text: var(--gray12);

  --success-bg: #16a34a;
  --success-border: var(--success-bg);
  --success-text: white;

  --info-bg: #97bce6;
  --info-border: var(--info-bg);
  --info-text: white;

  --warning-bg: #ffcc00;
  --warning-border: var(--warning-bg);
  --warning-text: black;

  --error-bg: hsl(0 84.2% 60.2%);
  --error-border: var(--error-bg);
  --error-text: white;
}
