thead > tr:last-child {
  @apply shadow-gray-300 dark:shadow-coolgray-mid;
}

thead.thead-primary > tr:first-child {
  @apply bg-gray-100 uppercase z-10 shadow-keyline-v shadow-gray-300 print:shadow-none print:border print:bg-gray-300;
}

thead.thead-secondary > tr:first-child {
  @apply bg-gray-50 uppercase z-10 shadow-keyline-v shadow-gray-200 print:shadow-none print:border print:bg-gray-200;
}

th {
  @apply font-normal text-left;
}

th,
td {
  padding: 0;
}

th.numeric,
td.numeric {
  @apply text-center tabular-nums;
}

table {
  @apply text-xs lg:text-sm print:text-xs;
}
