/* vietnamese */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/66950dcdfabb4e7f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/96bac409f5ca38b8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b0d37b8b851eee48-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cbb8bcfec3572e21-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d998c5a401767416-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d1475c40e69d0ed6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6fbce995081bb04a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/18890541b29657e0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/76f29b690e794976-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ed0713aabc469750-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ace9c6b312d37d07-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/95a978e26cc29d74-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d4a6d1072ea531dd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e1012b8d4e21a3f0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f5e5067cd50e2c82-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f2bfb63acfc2a372-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/642cf3f5695072c2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_1febe0';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/83c76cede88902c5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Barlow_Fallback_1febe0';src: local("Arial");ascent-override: 103.43%;descent-override: 20.69%;line-gap-override: 0.00%;size-adjust: 96.68%
}.__className_1febe0 {font-family: '__Barlow_1febe0', '__Barlow_Fallback_1febe0'
}.__variable_1febe0 {--fontBarlow: '__Barlow_1febe0', '__Barlow_Fallback_1febe0'
}

/* vietnamese */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d2dc27a4e7d9c770-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4c9acf47fab02fef-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bb42e0833ca2aa52-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5d1729a1e230e69c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/941978577d70375a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b04b29817df41d5a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/95718dcd5fe6dacd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/29d3871d7786f0f8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/77f798599f5111fb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/52ccffe2e9ffa814-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/cca5f86ff9c120e7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a1445b07705c83cd-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/60ffd0a01f841389-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bc042e5819eb6cde-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/75209ad4f73f0003-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/91bf6ac95242569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/dd3bbb700304c3e9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_Condensed_1eefee';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7559d8ca4e0054b1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Barlow_Condensed_Fallback_1eefee';src: local("Arial");ascent-override: 130.73%;descent-override: 26.15%;line-gap-override: 0.00%;size-adjust: 76.49%
}.__className_1eefee {font-family: '__Barlow_Condensed_1eefee', '__Barlow_Condensed_Fallback_1eefee'
}.__variable_1eefee {--fontBarlowCondensed: '__Barlow_Condensed_1eefee', '__Barlow_Condensed_Fallback_1eefee'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7bb4ad34d7ebf0d7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c1f853e4758089a8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c4943bfceab8361-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6e50af2f4c313e23-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7bb4ad34d7ebf0d7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c1f853e4758089a8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4c4943bfceab8361-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6e50af2f4c313e23-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7bb4ad34d7ebf0d7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c1f853e4758089a8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4c4943bfceab8361-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Onest_a85610';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/6e50af2f4c313e23-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Onest_Fallback_a85610';src: local("Arial");ascent-override: 92.20%;descent-override: 28.99%;line-gap-override: 0.00%;size-adjust: 105.20%
}.__className_a85610 {font-family: '__Onest_a85610', '__Onest_Fallback_a85610';font-style: normal
}.__variable_a85610 {--fontOnest: '__Onest_a85610', '__Onest_Fallback_a85610'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9248c7116a4e9d0a.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/92a4a2564edad155.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9c0e135edab4849d.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b54c100b6ed9a3f4.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/57df940ec5da011a.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9248c7116a4e9d0a.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/92a4a2564edad155.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9c0e135edab4849d.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b54c100b6ed9a3f4.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/57df940ec5da011a.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9248c7116a4e9d0a.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/92a4a2564edad155.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9c0e135edab4849d.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b54c100b6ed9a3f4.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/57df940ec5da011a.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_773f7d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}.__className_773f7d {font-family: '__Nunito_Sans_773f7d'
}.__variable_773f7d {--fontNunito: '__Nunito_Sans_773f7d'
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
text {
  font-family: var(--fontNunito);
}

thead > tr:last-child {
  --tw-shadow-color: #d1d5db;
  --tw-shadow: var(--tw-shadow-colored);
}

thead > tr:last-child:is(.dark *) {
  --tw-shadow-color: #3a4b5f;
  --tw-shadow: var(--tw-shadow-colored);
}

thead.thead-primary > tr:first-child {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  text-transform: uppercase;
  --tw-shadow: 0 0 0 1px;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #d1d5db;
  --tw-shadow: var(--tw-shadow-colored);
}

@media print {

  thead.thead-primary > tr:first-child {
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

thead.thead-secondary > tr:first-child {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
  text-transform: uppercase;
  --tw-shadow: 0 0 0 1px;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
}

@media print {

  thead.thead-secondary > tr:first-child {
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

th {
  text-align: left;
  font-weight: 400;
}

th,
td {
  padding: 0;
}

th.numeric,
td.numeric {
  text-align: center;
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

table {
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 1024px) {

  table {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media print {

  table {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.bg-stripes {
  background-image: repeating-linear-gradient(-45deg, rgba(243, 245, 246, 0.5) 0 10px, rgba(217, 217, 217, 0.5) 10px 16px);
}

/* Styling for a tooltip arrow. */

.tooltip-arrow {
  position: absolute;
  width: 6px;
  height: 6px;
  z-index: 1;
  border-color: inherit;
}

.tooltip-arrow::before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity, 1));
  width: 6px;
  height: 6px;
  box-sizing: content-box;
}

.tooltip-arrow[data-placement^='top'] {
  bottom: -2px;
}

.tooltip-arrow[data-placement^='top']::before {
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-color: inherit;
}

.tooltip-arrow[data-placement^='bottom'] {
  top: -3px;
}

.tooltip-arrow[data-placement^='bottom']::before {
  border-top-width: 1px;
  border-left-width: 1px;
  border-color: inherit;
}

.tooltip-arrow[data-placement^='left'] {
  right: -2px;
}

.tooltip-arrow[data-placement^='left']::before {
  border-top-width: 1px;
  border-right-width: 1px;
  border-color: inherit;
}

.tooltip-arrow[data-placement^='right'] {
  left: -3px;
}

.tooltip-arrow[data-placement^='right']::before {
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: inherit;
}

/* Styling for fat tooltip arrow. */

.tooltip-arrow.tooltip-big {
  width: 18px;
  height: 18px;
}

.tooltip-arrow.tooltip-big::before {
  width: 18px;
  height: 18px;
}

.tooltip-arrow.tooltip-big[data-placement^='top'] {
  bottom: -10px;
}

.tooltip-arrow.tooltip-big[data-placement^='bottom'] {
  top: -10px;
}

.tooltip-arrow.tooltip-big[data-placement^='left'] {
  right: -6px;
}

.tooltip-arrow.tooltip-big[data-placement^='right'] {
  left: -6px;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

[data-sonner-toaster][data-theme='light'][data-y-position='top'] {
  --normal-bg: #fff;
  --normal-border: var(--gray4);
  --normal-text: var(--gray12);

  --success-bg: #16a34a;
  --success-border: var(--success-bg);
  --success-text: white;

  --info-bg: #97bce6;
  --info-border: var(--info-bg);
  --info-text: white;

  --warning-bg: #ffcc00;
  --warning-border: var(--warning-bg);
  --warning-text: black;

  --error-bg: hsl(0 84.2% 60.2%);
  --error-border: var(--error-bg);
  --error-text: white;
}



